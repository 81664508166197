.crear_nivel_cerdo_container {
  max-width: 90%;
  margin: 25px auto;
  background-color: #fff;
  border-radius: 10px;

  .formulario {
    height: auto;
    padding: 25px;
    max-width: 95%;
    margin: 0 auto;

    .main_form {
      border: 2px solid #f0f0f0;
      border-radius: 10px;

      .header {
        border-bottom: 2px solid #f0f0f0;
        padding: 10px 15px;
        font-weight: 700;
        font-size: 16px;
      }

      .inputs {
        padding: 10px 15px;

        .label {
          margin: 14px 0;
        }

        .ant-input,
        .ant-select-selector {
          border-radius: 6px;
          height: 38px;
        }

        .ant-select-selector {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
