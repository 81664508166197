.dashboard_container {
  .stat_cards {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 16px;
  }

  .tabla-movimientos {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .dashboard_container {
    .stat_cards {
      justify-content: space-between;
    }
  }
}
