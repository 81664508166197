.collapsible_container {
  .collapsible_header {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid #f0f0f0;

    .sector_name {
      font-size: 16px;
      font-weight: 700;
      display: flex;
      align-items: center;
      column-gap: 20px;
      cursor: default;

      .anticon-down,
      .anticon-right {
        font-size: 12px;
      }
    }

    .delete_sector {
      font-size: 16px;
      font-weight: 400;
      display: flex;
      align-items: center;
      column-gap: 15px;
      cursor: pointer;
      color: var(--primary);
    }
  }

  .collapsible_content {
    .ant-form {
      padding: 10px 15px;

      .collapsible_form_section {
        display: flex;
      }
    }
  }
}
