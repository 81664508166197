.crear-excepcion {

  &__container {
    padding: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;

    .ant-tabs-tabpane {
        height: fit-content;
        border-radius: 0 10px 10px 10px;
    }

    .card-container > .ant-tabs-card .ant-tabs-content {
        height: 100%;
    }
  }

  &__contenedor {
    padding: 10px 80px !important;
    border-radius: 10px !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  }

  &__title {
    font-weight: 100;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 0;
  }

  &__rangoFecha {
    padding: 10px;
    background-color: #FEF8F4;
    border: 1px solid rgba(232, 119, 34, 0.1);
    border-radius: 5px; 
    padding: 15px 100px;

    .selector-fecha {
        .ant-picker-range {
            padding: 10px;
            border: 1px solid #D9D9D9;
            border-radius: 6px;
            margin-top: 10px;
            width: 100%;
        }
    }
    .selector-motivo {
      .ant-select-selector {
        padding: 10px !important;
        border: 1px solid #D9D9D9 !important;
        border-radius: 6px !important;
        margin-top: 10px !important;
        width: 100% !important;
        height: 44px !important;
        .ant-select-selection-placeholder {
          line-height: 24px;
        }
        .ant-select-selection-item {
          line-height: 24px;
        }
        input {
          height: 44px !important;
        }
      }
      .ant-select-arrow {
        padding-top: 14px;
      }
      .ant-select-clear {
        padding-top: 7px;
        margin-right: 24px;
      }
    }
    .ant-form-item-label>label {
      margin-left: 4px !important;
    }
    .another-reason {
     .ant-input {
        padding: 10px !important;
        border: 1px solid #D9D9D9 !important;
        border-radius: 6px !important;
        margin-top: 10px !important;
        width: 100% !important;
        height: 44px !important;
      }
      
    }
  }

  &__botonera {
    padding: 30px;

    .primary_button_container {
        margin-left: 20px;
    }
  }
}
