.navbar_drawer_container {
  .header {
    display: flex;
    justify-content: space-between;

    .bell_icon,
    .close_icon {
      font-size: 1.5rem;
    }

    .user {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 25px;
    }
  }

  .ant-divider {
    border-top-color: #fff;
  }

  .nav_links {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding-left: 0;
    margin-bottom: 0;
    align-items: center;

    .link_item {
      padding: 6px 18px;
      border-radius: 10px;
      color: #fff;

      .link_item-text {
        text-decoration: none;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        color: inherit;
      }

      .ant-space-item {
        font-family: "Roboto", sans-serif;
        cursor: pointer;
        display: flex;

        .anticon-down {
          font-size: 12px;
        }
      }
    }

    .active {
      background-color: rgba(255, 255, 255, 0.8);
      color: var(--primary);
      font-weight: 700;
    }
  }
}
