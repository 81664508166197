.profile_drawer_container {
  .profile_body {
    padding: 15px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(4, 1fr);
    column-gap: 30px;
    row-gap: 25px;

    .col-span-1 {
      grid-column: span 1 / span 1;
    }

    .title {
      font-weight: 600;
    }

    .subtitle {
      padding: 10px;
      background-color: #ebedee;
      border-radius: 8px;
      color: rgba(65, 64, 70, 0.6);
      cursor: default;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .profile_permisos {
    h4 {
      margin-bottom: 30px;
    }
    .permisos {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 30px;

      .niveles {
        padding-left: 25px;
      }

      .list {
        padding-left: 20px;
        list-style-type: none;

        li {
          margin: 10px 0;
        }
      }

      .granjas {
        .list {
          list-style-type: none;
          padding-left: 20px;

          li {
            margin: 10px 0;
          }
        }
      }
    }
  }
}
