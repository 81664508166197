.crear-declaracion {

  &__container {
    padding: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
  
 

    .ant-tabs-tabpane {
        height: fit-content;
        border-radius: 0 10px 10px 10px;
    }

    .card-container > .ant-tabs-card .ant-tabs-content {
        height: 100%;
    }
    .list-data-form {
      .ant-form-item-control-input-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .ant-col-12 {
          flex: 0 0 49%;
      }
      }
    }
  }



  &__contenedor {
    padding: 10px 0 !important;
    border-radius: 10px !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);

    .filter_container {
      display: flex;
      justify-content: space-between;
      padding: 15px;
  
      .filter_input {
        display: flex;
        column-gap: 20px;
        align-items: center;
      }
      .filters {
        display: flex;
        column-gap: 15px;
  
        .mobile-filters {
          display: flex;
          column-gap: 10px;
  
          .filter-box {
            width: 35px;
            height: 35px;
            display: grid;
            place-items: center;
            background-color: var(--primary);
            border-radius: 4px;
            span {
              color: #fff;
              font-size: 20px;
            }
          }
        }
   
      }
  
    }
    .searchDisabled {
      pointer-events: none;
    }
  }

  &__title {
    font-weight: 100;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 0;
    font-family: "Gotham";
    font-weight: 400;
  }

  &__subtitle {
    font-weight: 100;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 0;
    font-family: "Gotham";
    font-weight: 400;
  }
 

  &__rangoFecha {
    padding: 10px;
    background-color: #FEF8F4;
    border: 1px solid rgba(232, 119, 34, 0.1);
    border-radius: 5px; 
    padding: 15px 100px;

    .selector-fecha {
        .ant-picker-range {
            padding: 10px;
            border: 1px solid #D9D9D9;
            border-radius: 6px;
            margin-top: 0px;
            width: 100%;
        }
    }
  }

  &__botonera {
    padding: 30px;

    .primary_button_container {
        margin-left: 20px;
    }
  }
  //ConfirmacionFirmaDeclaracion
  &__containerDeclaracion {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
      }
      &__contenedorDeclaracion {
       padding: 10px 80px !important;
       border-radius: 10px !important;
       box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
     }
   
  &__pText{
    font-weight: 100;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 0;
    font-family: "Gotham";
    font-weight: 325;
    text-align: center;
  }
  &__titleDeclaracion {
    font-weight: 100;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 0;
    font-family: "Gotham";
    font-weight: 325;
    text-align: center; 
    margin-top: 30px;
   }
  }
