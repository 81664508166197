.first-login-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .login_card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90%;
    height: 490px;
    margin: 0 auto;
    border-radius: 20px;

    .login_card_img {
      img {
        max-width: 50%;
        height: auto;
        display: block;
        margin: 0 auto;
      }
    }

    .login_card_text {
      padding: 0 10px;
      font-family: "Gotham", sans-serif;

      .card_title {
        font-weight: 700;
        color: #141414;
        font-size: 16px;
        margin-bottom: 25px;
        text-align: center;
      }

      p.card_subtitle:nth-child(2) {
        margin-bottom: 25px;
      }

      .card_subtitle {
        color: #141414;
        font-size: 14px;
        margin-bottom: 0;
        padding: 0 0.5rem;
        text-align: center;
      }
    }

    .login_card_btn {
      text-align: center;
    }
  }

  .first-login-footer {
    display: flex;
    justify-content: center;

    .footer-text {
      color: rgba(62, 133, 247, 1);
    }
  }
}

@media only screen and (min-width: 426px) and (max-width: 1024px) {
  .first-login-container {
    .login_card {
      .login_card_img {
        img {
          max-width: 50%;
          height: auto;
          display: block;
          margin: 0 auto;
        }
      }

      .login_card_text {
        padding: 0 80px;
        font-family: "Gotham", sans-serif;

        .card_title {
          font-weight: 700;
          color: #141414;
          font-size: 16px;
          margin-bottom: 25px;
        }

        .card_subtitle {
          color: #141414;
          font-size: 14px;
          margin-bottom: 0;
          padding: 0 2rem;
        }
      }

      .login_card_btn {
        text-align: center;
      }
    }

    .first-login-footer {
      display: flex;
      justify-content: center;
      // position: absolute;
      // bottom: 50px;

      .footer-text {
        color: rgba(62, 133, 247, 1);
      }
    }
  }
}

@media only screen and (min-width: 1025px) {
  .first-login-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .login_card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 3rem;
      width: 40%;
      height: 490px;
      margin: 0 auto;
      border-radius: 20px;

      .login_card_img {
        img {
          max-width: 50%;
          height: auto;
          display: block;
          margin: 0 auto;
        }
      }

      .login_card_text {
        padding: 0 10px;
        font-family: "Gotham", sans-serif;

        .card_title {
          font-weight: 700;
          color: #141414;
          font-size: 16px;
          margin-bottom: 25px;
        }

        p.card_subtitle:nth-child(2) {
          margin-bottom: 25px;
        }

        .card_subtitle {
          color: #141414;
          font-size: 14px;
          margin-bottom: 0;
          padding: 0 3rem;
        }
      }

      .login_card_btn {
        text-align: center;
      }
    }
  }
}
