.pavos_container {
  .table_container {
    .filter_container {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;

      .filters {
        display: flex;
        column-gap: 15px;

        .filter_item {
          width: 35px;
          height: 35px;
          border-radius: 4px;
          background-color: var(--primary);
          display: grid;
          place-items: center;

          span {
            color: #fff;
            font-size: 20px;
          }
        }
      }

      .filter_input,
      .filter_buttons {
        display: none;
      }
    }

    th {
      background-color: #fff;
      border-bottom: none;
      font-weight: 700;
      cursor: default;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
      background: none !important;
    }

    tr:hover,
    thead:hover {
      background: #e8772212 !important;
    }

    .ant-table-tbody > tr > td {
      border-bottom: none;
      padding: 10px;
      cursor: default;
    }

    .ant-table-tbody {
      div.action {
        display: flex;
        justify-content: center;
        .ant-space-item {
          background: rgba(0, 48, 135, 0.08);
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          cursor: pointer;
        }
      }
    }

    .ver_detalle {
      color: var(--primary);

      border: 1px solid var(--primary);
      padding: 4px 10px;
      border-radius: 4px;
      cursor: pointer;
      font-family: "Roboto", sans-serif;
      text-align: center;
    }

    .ant-table-pagination.ant-pagination {
      padding: 50px 0;
      // padding-bottom: 25px;
    }

    .ant-pagination-item {
      border-radius: 6px;
      //background-color: #f3f5f7;
      background-color: #fff;
      border: none;
      a {
        color: #8c8c8c;
      }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      .ant-pagination-item-link {
        border-radius: 6px;
      }
    }

    .ant-pagination-item-active {
      background-color: var(--primary);
      a {
        color: #fff;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .pavos_container {
    .table_container {
      .filter_container {
        display: flex;
        justify-content: space-between;
        margin: 20px 0;

        .filters {
          display: none;
          column-gap: 15px;

          .filter_item {
            width: 35px;
            height: 35px;
            border-radius: 4px;
            background-color: var(--primary);
            display: grid;
            place-items: center;

            span {
              color: #fff;
              font-size: 20px;
            }
          }
        }

        .filter_input {
          display: flex;
          column-gap: 20px;
        }

        .filter_buttons {
          display: flex;
          column-gap: 20px;
        }
      }
    }
  }
}
