.formulario_roles {
  .bottom_form {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    .title {
      font-size: 1.5rem;
      margin: 15px 0;
    }

    .switch_input {
      display: flex;
      column-gap: 0.5rem;

      .ant-switch-checked {
        background-color: var(--primary);
      }
    }
  }
  .section {
    .section-item {
      margin: 15px 0;
    }
    .title {
      font-size: 1.5rem;
      margin: 15px 0;
    }

    .subtitle {
      font-size: 1rem;
      margin: 15px 0;

      .select-all {
        .ant-checkbox-inner {
          width: 15px;
          height: 15px;
        }
        span {
          font-size: 0.75rem;
        }

        label {
          span {
            font-size: 1.5em;
          }
        }
      }
    }

    .sub-section {
      .ant-checkbox-group {
        padding-left: 1.5rem;
        display: flex;
        flex-direction: column;
        row-gap: 15px;
      }
    }
  }

  .ant-divider {
    margin: 35px 0;
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: var(--primary);
  }
}
