.formulario-permisos {
  background-color: #fff;
  margin: 2rem 0;
  border-radius: 10px;

  &-title {
    font-weight: 500;
    font-size: 1rem;
    margin: 1.5rem 0;
  }
}
