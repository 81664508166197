.alert_container {
  width: 90%;
  margin: 2rem auto;

  .ant-alert {
    border-radius: 10px;
  }
}

@media only screen and (min-width: 1024px) {
  .alert_container {
    width: 70%;
  }
}
