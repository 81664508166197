.user-details-container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .user-info-details {
    min-height: 600px;
    border-radius: 6px;
    background: #fff;
    padding: 16px 8px;

    .user-avatar {
      padding: 8px;
    }

    .user-name {
      padding: 8px;
      font-weight: 600;
      font-size: 1.05rem;
    }

    .user-info {
      padding: 8px;
      font-size: 0.9rem;

      .user-info-label,
      .user-info-value {
        display: block;
      }

      .user-info-label {
        color: rgba(38, 38, 38, 0.8);
        font-size: 0.9rem;
      }

      .user-info-value {
        color: rgba(0, 0, 0, 1);
        font-size: 1rem;
        font-weight: 600;
      }
    }
  }
}

.user-details-container-no-profile {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  .user-info-details-no-profile {
    border-radius: 6px;
    background: #fff;
    padding: 16px 8px;
  }

  .user-avatar-no-profile {
    padding: 8px;
  }

  .user-name {
    padding: 8px;
    font-weight: 600;
    font-size: 1.05rem;
  }
}
