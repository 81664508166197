.card-invalidate-exception {
  .ant-card-body {
    padding: 0px;
  }

  .col-span-model-exception {
    margin-bottom: 24px;
  }

  .footer_modal_exception {
    display: flex;
    justify-content: space-evenly;
    margin-top: 24px;
  }
}
