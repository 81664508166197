.users_container {
  padding: 10px;

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 6px 6px 0 0;
  }
  .ant-tabs {
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #000;
        font-weight: 400;
        font-size: 18px;
      }
    }

    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
}

@media only screen and (min-width: 426px) {
  .users_container {
    padding: 40px;

    .ant-tabs {
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #000;
          font-weight: 400;
          font-size: 18px;
        }
      }

      .ant-tabs-nav {
        margin-bottom: 0;
      }
    }
  }
}
