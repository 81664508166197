.title-codigo{
    padding-bottom: 20px;
    size: 14px;
    font-family: "Gotham";
}

    
     .title-codigo span{
      font-family: "Gotham";
      font-weight: 700;
     }

     .content-button-codigo{
       display: flex;
       justify-content: center;
       align-items: center;
       .textCount {
        text-align: center;
       }
       .count {
        text-align: center;
        padding: 10px 0 30px 0;
        .ant-statistic-content {
          font-size: 30px;
          color: rgba(38, 38, 38, 1);
        }
       }
       .text-validate {
        text-align: center;
        padding: 10px 0 30px 0;
        color: rgba(38, 38, 38, 1);
        font-size: 18px;
        font-weight: 500;
       }
       .content-valid {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        .text-codigo {
          font-size: 14px;
        }
       }
     }
.button-cod{
    border: 1px solid #003975;
    border-radius: 6px;
    background-color: var(--primary);
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    
}
.text-codigo{
    font-family: "Gotham";
      font-weight: 375;
      font-size: 14px; 
      align-items: center;
      text-align: center; 
      color: #262626;
      padding-bottom: 4px;
}
.textCount{
  font-family: gotham;
  font-weight: 325;
  font-size: 14px;
}