.tabla_solicitudes {
  background-color: #fff;

  .ant-table-thead {
    tr {
      th {
        background-color: #fff;
      }
    }

    tr {
      th::before {
        width: 0 !important;
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        border-bottom: none;

        &:nth-child(3) {
          padding: 8px;
        }
      }
    }


    div.action {
      position: relative;
      height: 30px;
      width: 30px;
      background: rgba(0, 48, 135, 0.08);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      cursor: pointer;
      align-items: center;
    }

    p.area {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin-bottom: 0;

      .anticon {
        width: 32px;
        height: 32px;
        background: rgba(232, 119, 34, 0.1);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    p.estado_solicitud {
      display: flex;
      align-items: center;
      column-gap: 5px;
      margin-bottom: 0;
      div.fondo_icon {
        background: #FDF1E9;
        border-radius: 6px;
      }
    }
    .content_status {
      display: flex;
      align-items: center;
      img {
        width: 7px;
      }
      .role_active{
        text-transform: capitalize;
        margin-left: 13px;
      }
    }
  }
  .filter_container {
    display: flex;
    justify-content: space-between;
    padding: 20px;

    .filters {
      display: flex;
      column-gap: 15px;
      .filter_item {
        width: 35px;
        height: 35px;
        border-radius: 4px;
        background-color: var(--primary);
        display: grid;
        place-items: center;

        span {
          color: #fff;
          font-size: 20px;
        }
      }
    }

    .filter_input,
    .filter_buttons {
      display: none;
    }
  }
  .iconButton {
    border: 1px solid #003975;
    color: #ffff;
    border-radius: 6px;
    background-color: var(--primary);
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-pagination {
    padding: 25px 15px;

    .ant-pagination-disabled {
      button {
        border-radius: 6px;
      }
    }

    .ant-pagination-item {
      border-radius: 6px;
    }

    .ant-pagination-item-active {
      background-color: var(--primary);

      a {
        color: #fff;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .tabla_solicitudes {
    background-color: #fff;

    .ant-table-thead {
      tr {
        th {
          background-color: #fff;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          border-bottom: none;
        }
      }
    }
    .filter_container {
      display: flex;
      justify-content: space-between;
      padding: 20px;

      .filters {
        display: none;
        column-gap: 15px;
        .filter_item {
          width: 35px;
          height: 35px;
          border-radius: 4px;
          background-color: var(--primary);
          display: grid;
          place-items: center;

          span {
            color: #fff;
            font-size: 20px;
          }
        }
      }

      .filter_input {
        display: flex;
        column-gap: 20px;
      }

      .filter_buttons {
        display: flex;
        column-gap: 20px;
      }
    }
  }
}
