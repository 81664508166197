.registro-movimientos-table {
  .filter-container {
    display: flex;
    justify-content: space-between;
    padding: 32px 16px;

    .filters {
      display: flex;
      column-gap: 16px;
    }

    .search-filter {
      .ant-input-search-button {
        min-height: 36px;
      }

      .ant-input-search
        > .ant-input-group
        > .ant-input-group-addon:last-child
        .ant-input-search-button {
        border-radius: 0 6px 6px 0;
      }

      .ant-input-search
        > .ant-input-group
        > .ant-input-group-addon:last-child
        .ant-input-search-button:not(.ant-btn-primary) {
        color: #fff;
        background-color: var(--primary);
      }
    }
  }


  .ant-table {
    .ant-table-thead {
      tr > th {
        color: #000;
        font-weight: 600;
      }
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(
        .ant-table-row-expand-icon-cell
      ):not([colspan]):before {
      content: none;
    }
  }
}
