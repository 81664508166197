.crear_zona_container {
  max-width: 90%;
  margin: 25px auto;
  background-color: #fff;
  border-radius: 10px;

  .formulario {
    padding: 24px;

    .main_form {
      border-radius: 10px;
      border: 2px solid #f0f0f0;

      .header {
        font-weight: 700;
        font-size: 16px;
        border-bottom: 2px solid #f0f0f0;
        padding: 10px 15px;
      }

      .inputs {
        padding: 10px 15px;
      }
    }
  }
}
