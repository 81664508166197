.row_item_piramides {
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 0.75rem;
  align-items: center;

  .item_piramides {
    grid-column: 1 / 2;
    height: 30px;
    left: 755px;
    top: 104px;
    font-family: "Gotham";
    font-style: normal;
    font-weight: 325;
    font-size: 16px;
    color: #262626;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      overflow: visible;
      white-space: normal;
      text-overflow: unset;
    }
  }

  .ant-form-item {
    margin: 0;

    .item_status {
      grid-column: 2 / 3;
      display: flex;
      align-items: center;
      column-gap: 10px;
      font-size: 12px;
      justify-self: end;

      .ant-switch-checked {
        background: var(--primary);
      }

      span {
        cursor: default;
      }
    }
  }

  .item_delete {
    justify-self: end;
    cursor: pointer;
  }
}

.delete_zone {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
}

.active_zone {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;
  color: #434343;
  flex: none;
  flex-grow: 0;
}

@media only screen and (min-width: 1024px) {
  .row_item_piramides {
    padding: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 0.75rem;
    align-items: center;

    .item_piramides {
      grid-column: 1 / 3;
      height: 30px;
      left: 755px;
      top: 104px;
      font-family: "Gotham";
      font-style: normal;
      font-weight: 325;
      font-size: 16px;
      color: #262626;
    }

    .item_status {
      grid-column: 3 / 4;
      display: flex;
      align-items: center;
      column-gap: 10px;
      font-size: 12px;

      .ant-switch-checked {
        background: var(--primary);
      }

      span {
        cursor: default;
      }
    }

    .item_delete {
      justify-self: end;
      cursor: pointer;
    }
  }

  .delete_zone {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
  }

  .active_zone {
    font-family: "Gotham";
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 17px;
    color: #434343;
    flex: none;
    flex-grow: 0;
  }
}
