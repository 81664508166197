.ant-modal-root {
  border-radius: 10px;

  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-title {
      font-weight: 700;
    }

    .ant-modal-header {
      border-radius: 10px 10px 0 0;
    }

    .ant-modal-body {
      .delete-modal-wrapper {
        .delete-modal-body {
          padding: 0 0 8px 0;

          .delete-modal-body-info {
            font-weight: 600;
            font-size: 16px;
          }
        }

        .modal-confirm {
          margin-top: 8px;
          .ant-checkbox-wrapper {
            display: flex;
            align-items: flex-start;
          }
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: var(--primary);
            border-color: var(--primary);
          }
        }

        .delete-modal-footer {
          padding: 16px 0 0 0;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }
    }
  }
}
.delete-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 10000;
  cursor: default;

  .delete-modal.enter-done {
    opacity: 1;
    pointer-events: visible;
  }

  .delete-modal.exit {
    opacity: 0;
  }

  .delete-modal-content {
    width: 500px;
    background-color: #fff;
    border-radius: 10px;

    .ant-modal-content {
      border-radius: 10px;

      .ant-modal-title {
        font-weight: 700;
      }

      .ant-modal-header {
        border-radius: 10px 10px 0 0;
      }

      .ant-modal-body {
        .delete-modal-wrapper {
          .delete-modal-body {
            padding: 0 0 16px 0;

            .delete-modal-body-info {
              font-weight: 600;
              font-size: 16px;
            }
          }

          .delete-modal-footer {
            padding: 16px 0 0 0;
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .ant-modal-root {
    .ant-modal-content {
      .ant-modal-body {
        .delete-modal-wrapper {
          .delete-modal-footer {
            flex-direction: row;
          }
        }
      }
    }
  }
}
