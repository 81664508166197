.ant-spin-text {
  font-family: Gotham !important;
}

.background-drawer {
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
}

.spinner-drawer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.font-drawer {
  font-family: Gotham !important;
}

