.ant-drawer {
  .ant-drawer-title {
    font-size: 24px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
    margin: 10px 0;
  }
  .ant-drawer-body {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: var(--primary);
      }
    }
    .ant-collapse-item {
      .ant-collapse-header {
        font-weight: 700;
        font-size: 16px;
        font-family: "Roboto", sans-serif;
      }

      .ant-checkbox-group {
        .ant-checkbox-group-item {
          display: flex;
          margin: 15px 0;
          span {
            display: block;
            color: #434343;
            font-family: "Roboto", sans-serif;
          }
        }
      }
    }
    .ant-collapse {
      margin: 15px 0;

      .filter-criteria {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .ant-checkbox-wrapper {
          display: flex;
          margin-left: 0;
        }
      }
    }
  }
}
