.ant-modal-root {
  border-radius: 10px;

  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-title {
      font-weight: 700;
    }

    .ant-modal-header {
      border-radius: 10px 10px 0 0;
    }

    .ant-modal-body {
      .modal-qr {
        text-align: center;

        p {
          font-size: 20px;
        }
      }

      .confirm-modal-wrapper {
        .confirm-modal-body {
          padding: 0 0 16px 0;

          .confirm-modal-body-info {
            font-weight: 600;
            font-size: 16px;
          }
        }

        .confirm-modal-footer {
          padding: 16px 0 0 0;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .confirm-modal-footer.confirm-modal-botonera {
          flex-direction: column !important;
          align-items: center;
        }
      }

      .modal-invalidar-cuarentena {
        p {
          margin: 10px 0;
        }
        .confirm-modal-footer.confirm-modal-botonera {
          flex-direction: row !important;
          .ant-btn span {
            font-weight: 300;
            font-size: 16px;
          }
        }
      }
    }
  }
}
.confirm-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 10000;
  cursor: default;
}

.confirm-modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.confirm-modal.exit {
  opacity: 0;
}

.confirm-modal-content {
  width: 500px;
  background-color: #fff;
  border-radius: 10px;

  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-title {
      font-weight: 700;
    }

    .ant-modal-header {
      border-radius: 10px 10px 0 0;
    }

    .ant-modal-body {
      .confirm-modal-wrapper {
        .confirm-modal-body {
          padding: 0 0 16px 0;

          .confirm-modal-body-info {
            font-weight: 600;
            font-size: 16px;
          }
        }

        .confirm-modal-footer {
          padding: 16px 0 0 0;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .ant-modal-root {
    .ant-modal-content {
      .ant-modal-body {
        .confirm-modal-wrapper {
          .confirm-modal-footer {
            flex-direction: row;
          }
        }
      }
    }
  }
}
.delete-zona-modal-body {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 325;
  font-size: 16px;
  line-height: 22px;
  color: #434343;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.delete-zona-modal {
  padding: 1.5rem;
}
.delete-zona-title {
  font-family: "Gotham";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  color: #141414;
}
.delete-zona-modal-header {
  padding: 0 !important;
}
.delete-zona-modal-footer {
  padding: 0 !important;
}
