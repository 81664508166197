.primary_button_container {
  .ant-btn {
    border: 1px solid #003975;
    border-radius: 6px;
    background-color: var(--primary);
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    // gap: 10px;

    // span {
    //   color: #fff;
    //   font-size: 14px;
    //   font-weight: 500;
    //   font-family: "Gotham", sans-serif;
    // }

    .button_text {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      font-family: "Gotham", sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .anticon {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 16px !important;
      }
    }

    &:hover {
      background-color: var(--primary);
    }

    &:focus {
      background: #1952be;
    }
  }
}

.secondary_button_container {
  .ant-btn {
    border: 1px solid #003975;
    border-radius: 6px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: var(--primary);
      font-size: 16px;
      font-weight: 500;
      font-family: "Gotham", sans-serif;
    }

    .anticon {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 16px !important;
      }
    }
  }
}

.mas_opciones_button_container {
  display: flex;
  justify-content: space-between;
}

.mas_opciones_button{
  width: 110px;
  height: 20px !important;
  font-family: "Gotham";
  font-style: normal;
  font-weight: 350 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  text-align: center !important;
  color: #003087 !important;
  flex: none;
  order: 1;
  flex-grow: 0;
  border: 0px solid #d9d9d9 !important;
}
.enviar-codigo_container {
  .ant-btn {
    border: 1px solid #003975;
    border-radius: 6px;
    background-color: var(--primary);
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;

  

    .button_text {
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      font-family: "Gotham", sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .anticon {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 16px !important;
      }
    }

    &:hover {
      background-color: var(--primary);
    }

    &:focus {
      background: #1952be;
    }
  }
}