.cerdo_tabs_form_container {
  .ant-form-item {
    margin-top: 10px;
  }
  .ant-form-item .ant-select .ant-select-selector {
    border-radius: 6px;
  }
  .radio_btn_label {
    font-weight: 700;
    margin-bottom: 10px;
  }

  .sectores_container {
    .sector_section {
      .ant-collapse-content {
        .ant-collapse-content-box {
          padding: 12px;
        }
        .collapse_areas {
          display: grid;
          column-gap: 15px;
          grid-template-columns: repeat(6, 1fr);
          grid-template-rows: repeat(3, 1fr);
          grid-template-areas:
            "nivelActual centroSap tipoEst tipoEst pabellones rup"
            "direccion direccion region region coordEst coordNort"
            "sectorial sectorial direcSag direcSag ciudad pabco";

          .title {
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 700;
            font-family: "Gotham", sans-serif;
          }

          .subtitle {
            color: #8c8c8c;
            font-size: 14px;
            font-family: "Gotham", sans-serif;
          }

          .nivel_actual {
            grid-area: nivelActual;
          }

          .centro_sap {
            grid-area: centroSap;
          }

          .tipo_establecimiento {
            grid-area: tipoEst;
          }

          .pabellones {
            grid-area: pabellones;
          }

          .rup {
            grid-area: rup;
          }

          .direccion {
            grid-area: direccion;
          }

          .region {
            grid-area: region;
          }

          .coordenada_este {
            grid-area: coordEst;
            p {
              font-size: 14px;
            }
          }

          .coordenada_norte {
            grid-area: coordNort;

            p {
              font-size: 14px;
            }
          }

          .sectorial_sag {
            grid-area: sectorial;
          }

          .direccion_sag {
            grid-area: direcSag;
          }

          .ciudad {
            grid-area: ciudad;
          }

          .pabco {
            grid-area: pabco;
          }
        }
      }
    }
  }
}
