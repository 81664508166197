.nuevo_nivel_cerdos {
  .header {
    margin-top: 25px;
    .header_title {
      background: rgba(0, 48, 135, 0.05);
      border-radius: 6px;
      padding: 9px 19px;
      font-size: 16px;
    }
    .current_nivel {
      font-weight: 700;
    }
  }

  .form_container {
    .ant-tabs {
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: black;
          font-size: 18px;
          font-weight: 700;
        }
      }
      .ant-tabs-tabpane {
        background-color: #fff;
        height: auto;
      }
      .ant-tabs-tabpane-active {
        height: auto;
      }
    }
  }
}
