.detalle_cuarentena_container {
  width: 100%;
  margin: 10px 0;
  // display: flex;
  // justify-content: center;

  .ant-table {
    .ant-table-thead tr th {
      background-color: #fff;
    }
  }
}

.detalle_niveles_cuarentena {
  margin-top: 15px;
}
.ant-card {
  width: 100%;
  border-radius: 10px;
}
.capa_buttons {
  display: inline-block;
}
.title {
  margin-bottom: 100px;
}

.cuarentena_sector {
  margin-top: 20px;
}

.btn-container {
  display: block;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 16px;

  .type_selector {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    flex-grow: 1;

    .selector {
      flex-grow: 1;
      width: 100px;
      height: 38px;
      padding: 10px 10px;
      cursor: pointer;
      border-radius: 6px;
      background-color: #d9d9d980;
      color: #8c8c8c;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;

      &:active {
        transform: scale(1.1);
      }
    }

    .active {
      background-color: var(--primary);
      color: #fff;
    }
  }

  .action-btns {
    display: flex;
    gap: 16px;
    width: 100%;
    .secondary_button_container {
      width: 100%;
      button {
        min-width: 100%;
      }
    }
  }
}

.type_selector,
.action-btns {
  width: 100%;
}

.backHistory {
  font-size: 15px;
}

.form_detalle {
  .ant-col {
    padding: 2px;
    margin: 0;
  }
  .ant-input {
    // padding: 10px;
    border-radius: 3px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .ant-select-selector {
    border-radius: 3px;
  }
  .ant-select-selection-item {
    padding: 10px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) {
  .detalle_cuarentena_container {
    width: 100%;
    margin: 10px 0;

    .cuarentena-selected-sector {
      width: 100%;
      margin-top: 10px;
      p {
        margin: 5px 0;
      }
      .ant-select {
        width: 100%;
      }
    }
    .ant-table {
      .ant-table-thead tr th {
        background-color: #fff;
      }
    }

    .btn-container {
      flex-direction: row;
      flex-wrap: nowrap;
      .type_selector {
        flex-grow: 2;
        .selector {
          flex-grow: 0;
        }
      }
      .action-btns {
        max-width: 25%;
        display: flex;
        gap: 16px;
        justify-content: end;

        .secondary_button_container {
          max-width: 50%;
          button {
            max-width: 50%;
          }
        }
      }
    }
  }
}
.detalle-cuarentena-container {
  padding: 0 1.5rem;

  .volver {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    cursor: pointer;
    .anticon-left-circle {
      font-size: 1rem;
      color: var(--primary);
    }
  }

  .cuarentena-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 16px;

    .cuarentena-info-container {
      grid-column: span 1;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      .datos-cuarentena-caja {
        p {
          font-weight: 500;
          font-style: italic;
          margin-bottom: 5px;
        }
        .ant-badge-status-dot {
          height: 9px;
          width: 9px;
        }
        .datos-cuarentena-caja-detalle p {
          font-weight: normal;
          font-style: normal;

          .anticon-close-circle {
            color: #ff3c3e;
          }
          .anticon-check-circle {
            color: #14c159;
          }
        }
        ul {
          list-style-type: none;
          padding-inline-start: 20px;
          li{
            padding-left: 12px;
          }
        }
      }

      .cuarentena-details-container {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
      }
    }

    .cuarentena-tables {
      grid-column: span 3;

      .ant-tabs {
        .ant-tabs-tab {
          border-radius: 6px 6px 0 0;
          border: 0;
        }
        .ant-tabs-nav {
          margin-bottom: 0;
        }

        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #000;
            font-weight: 700;
          }
        }
      }
    }
  }
}

.registro-sectores-table {
  .filter-container {
    display: flex;
    justify-content: space-between;
    padding: 32px 16px;

    .filters {
      display: flex;
      column-gap: 16px;
    }

    .search-filter {
      .ant-input-search-button {
        min-height: 36px;
      }

      .ant-input-search
        > .ant-input-group
        > .ant-input-group-addon:last-child
        .ant-input-search-button {
        border-radius: 0 6px 6px 0;
      }

      .ant-input-search
        > .ant-input-group
        > .ant-input-group-addon:last-child
        .ant-input-search-button:not(.ant-btn-primary) {
        color: #fff;
        background-color: var(--primary);
      }
    }
  }

  .ant-table {
    .ant-table-thead {
      tr > th {
        color: #000;
        font-weight: 700;
        background-color: #fff;
      }
    }
  }
}
