.login_container {
  background-image: url("../../images/login_background.png");
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  height: 100vh;
  display: grid;
  place-content: center;
  .login_card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 460px;
    height: 490px;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 20px;

    .login_card_img {
      img {
        max-width: 50%;
        height: auto;
        display: block;
        margin: 0 auto;
      }
    }

    .login_card_text {
      padding: 0 80px;
      font-family: "Gotham", sans-serif;

      .card_title {
        font-weight: 700;
        color: #141414;
        font-size: 16px;
        margin-bottom: 25px;
      }

      .card_subtitle {
        color: #141414;
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    .login_card_btn {
      margin: 0 auto;
    }
  }
}
