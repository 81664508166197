.contenedor_noches_vacio {
  width: 90%;
  margin: 0 auto;
  padding: 8px 20px;

  .ant-card-component {
    min-height: 100%;
  }

  .card_selector {
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    height: 129px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-card-body {
      width: 100%;
      padding: 31px 61px;
    }
  }

  .title {
    font-weight: 100;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
  }
  .header {
    height: 30px;
  }
  .layout {
    flex-direction: row !important;
  }

  .ant-layout-sider {
    background-color: #ffffff !important;
  }

  .historial {
    margin: 10px 0;
    width: 250px;
    background: #003087;
    border-radius: 6px;
  }

  .layout .ant-layout {
    margin: -45px 0px 0 20px;
  }

  .react-select {
    border-color: red;
    color: red;
  }

  .content-btn-vacio {
    display: flex;
    justify-content: center;
    margin-top: 60px;
  }

  .ant-card-body .ant-menu label {
    font-size: 0.8em;
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
  }

  .ant-card-body .ant-menu-inline {
    border: 0;
  }

  .ant-card-body
    li.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    background-color: #003087;
    color: #fff;
    border-radius: 6px;
    font-weight: bold;
    opacity: 0.7;
  }

  .ant-menu-inline .ant-menu-item-selected:after {
    border: 0;
  }

  .ant-card-body .ant-menu-inline.ant-menu-root .ant-menu-item {
    font-size: 0.8em;
  }

  .ant-collapse-content-box select {
    width: 100%;
  }

  .ant-collapse-content-box .ant-col {
    padding: 5px 10px;
  }

  .ant-collapse-header {
    background-color: #fff;
    font-weight: bold;
  }

  .ant-collapse.ant-collapse-icon-position-start {
    border: 0;
    background-color: transparent;
    color: transparent;
  }

  .ant-collapse-item {
    border-radius: 6px;
    margin-bottom: 29px;
    border-radius: 10px;
    border: 1px solid var(--neutral-4, #F0F0F0);
    .ant-collapse-content {
      border-top: 1px solid #F0F0F0;
    }
  }

  .h1 {
    font-size: 17px;
  }

  .form_general select {
    margin-bottom: 10px;
  }

  .form_unidad_cuarentenaria h4 {
    margin-bottom: 20px;
    font-weight: bold;
  }

  .form_unidad_cuarentenaria select {
    margin-bottom: 10px;
  }

  .transversales button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 26px;
    gap: 6px;
    background: #003087;
    border-radius: 6px;
    position: relative;
  }

  .perfil_usuario button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 9px 26px;
    gap: 6px;
    background: #003087;
    border-radius: 6px;
    position: relative;
  }

  form {
    background: white;
    width: 749px;
    margin: auto;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  select {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;

    width: 180px;
    height: 35px;

    /* Neutral/1 */

    background: #ffffff;
    /* Neutral/5 */

    border: 1px solid #d9d9d9;
    border-radius: 6px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  .espacioarea {
    background: white;
    width: 749px;
    margin: auto;
    border-radius: 10px;
    padding: 20px;
  }

  .area {
    display: flex;
    justify-content: center;
    gap: 26px;
    align-items: center;
    margin: auto;
    border-radius: 5px;
    border: 1px solid rgba(232, 119, 34, 0.10);
    background: rgba(232, 119, 34, 0.05);
    height: 68px;
    width: 100%;
  }
  .title_noches {
    font-size: 16px;
    font-weight: bold;
  }
  .row_general_list {
    display: flex;
    align-items: baseline;
  }
}
