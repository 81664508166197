.declaracion-visitas {
  &__container {
    padding: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;

    .ant-tabs-tabpane {
      height: fit-content;
      border-radius: 0 10px 10px 10px;
    }

    .card-container > .ant-tabs-card .ant-tabs-content {
      height: 100%;
    }
  }
  &__contenedor {
    padding: 10px 80px !important;
    border-radius: 10px !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05) !important;
  }

  &__title {
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 0;
    font-family: "Gotham";
    font-weight: 400;
  }

  &__language {
    .ant-form-item-row {
      display: flex;
      justify-content: center;
      align-items: baseline;
      flex-direction: row !important;
      flex-wrap: nowrap;
      .ant-form-item-label {
        margin-right: 20px;
        overflow: visible !important;
      }
    }
    
  }

  &__title-declaracion-visita {
    padding-bottom: 20px;
    size: 14px;
    font-family: "Gotham";
  }
  &__price-features li {
    size: 14px;
    font-family: "Gotham";
    width: 100%;
    margin-bottom: 5px;
  }
  &__price-features li span {
    font-family: "Gotham";
    font-weight: 700;
  }
  &__content-check {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 34px 0 34px 0;
  }
  &__text-ckeck {
    size: 14px;
    font-family: "Gotham";
    padding-left: 5px;
    font-weight: 400;
  }
  &__cursive {
    font-family: "Gotham";
    font-weight: 300;
    font-style: italic;
    // padding-bottom: 15px;
    // margin-bottom: 5px;
  }
  &__botonera {
    padding: 30px;

    .primary_button_container {
      margin-left: 20px;
    }
  }
 &__messageAgreeError {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
 }
}
