.stat_card_container {
  min-height: 80px;
  background: #fff;
  border-radius: 6px;
  padding: 8px;

  .stat-card-body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 100%;
    place-items: center;

    .stat-card_icon {
      width: 32px;
      height: 32px;
      background: red;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-self: start;
    }

    .stat-card_content {
      cursor: default;
      .stat-card_count {
        font-weight: 700;
        font-size: 1.05rem;
        line-height: 1.05rem;
        margin-bottom: 4px;
      }

      .stat-card_title {
        font-size: 0.75rem;
        color: #999;
        line-height: 0.75rem;
      }
    }

    .stat-card_moreinfo {
      cursor: pointer;
      justify-self: end;
    }

    .greenIcon {
      background: rgba(20, 193, 89, 0.08);
    }

    .redIcon {
      background: rgba(255, 0, 0, 0.08);
    }

    .yellowIcon {
      background: rgba(255, 255, 0, 0.08);
    }

    .blueIcon {
      background: rgba(57, 164, 224, 0.08);
    }

    .purpleIcon {
      background: rgba(128, 0, 128, 0.08);
    }

    .orangeIcon {
      background: rgba(255, 165, 0, 0.08);
    }
  }
}
