.input_container {
  .ant-input {
    border-radius: 5px 0 0 5px;
    height: 35px;
  }
  .ant-input-search-button {
    background-color: var(--primary);
    height: 35px;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0 5px 5px 0;

    &:hover {
      background-color: var(--primary);
    }
  }

  .anticon-search {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}

.search-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .ant-input {
    border-radius: 5px 0 0 5px;
    height: 38px;
  }

  .ant-btn:not([disabled]):hover {
    text-decoration: none;
  }
  .ant-input-search-button {
    background-color: var(--primary);
    height: 38px;
    outline: 0;
    outline-color: initial;
    outline-style: initial;
    outline-width: 0;
    text-decoration: none;
  }

  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    border-radius: 0 5px 5px 0;

    &:hover {
      background-color: var(--primary);
    }
  }

  .anticon-search {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }

  .ant-btn:active,
  .ant-btn:focus,
  .ant-btn:hover {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
  }
}
