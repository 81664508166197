.piramides-container {
  .editar-piramides-header {
    display: flex;
    margin: 32px 0;

    .editar-piramides-header_title {
      flex: 1;
      font-size: 24px;
      color: #262626;

      .bold {
        font-weight: 700;
      }
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #262626;
    font-weight: 700;
  }

  .ant-tabs-tab {
    background: #fff;
    padding: 12px 24px;
    border-radius: 6px 6px 0 0;
  }

  .ant-tabs-tab-btn {
    color: #8c8c8c;
    font-weight: 400;
    font-size: 16px;
  }

  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav,
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-ink-bar {
    background: var(--primary);
  }

  // .piramides-wrapper {
  //   .piramides-header {
  //     background-color: #fff;
  //     padding: 36px 12px;
  //   }
  // }
}
