.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 1000;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modal-content {
  width: 500px;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  transform: translateY(-200px);
  z-index: 1000;
}

.modal.enter-done .modal-content {
  transform: translateY(0);
}

.modal.exit .modal-content {
  transform: translateY(-200px);
}

.modal-header,
.modal-footer {
  padding: 1rem;
}

.modal-title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 700;
}

.modal-body {
  padding: 1rem;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.modal-body_success {
  border-top: 1px solid rgba(20, 193, 89, 0.05);
  border-bottom: 1px solid rgba(20, 193, 89, 0.05);
}

.modal-body_error {
  border-top: 1px solid rgba(232, 37, 37, 0.1);
  border-bottom: 1px solid rgba(232, 37, 37, 0.1);

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;

    .list-item {
      margin: 0.5rem 0;
      font-weight: 700;
    }
  }
}

.modal-header {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.modal-success {
  background: rgba(20, 193, 89, 0.05);
}

.modal-error {
  background: rgba(232, 37, 37, 0.1);
}

.modal-icon {
  width: 32px;
  height: 32px;
  border-radius: 10px;
  display: grid;
  place-items: center;
}

.modal-icon_success {
  background: rgba(20, 193, 89, 0.05);
}

.modal-icon_error {
  background: rgba(232, 37, 37, 0.1);
}
