.crear-usuario {
  width: 100%;
  .main-form {
    width: 90%;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 10px;
    padding: 35px 16px;

    .main-form-fields {
      .form-title {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 16px;
      }
    }

    .main-form-dates {
      .form-title {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 16px;
      }

      .ant-picker {
        border-radius: 6px;
      }
    }

    .main-form-permissions {
      .form-title {
        font-size: 1rem;
        font-weight: 600;
        margin: 16px 0;
      }
      .main-form-permission-buttons {
        display: flex;
        justify-content: space-between;
        gap: 16px;
        flex-wrap: wrap;

        button {
          flex: 1;
          height: 40px;
          padding: 9px 19px;
          border-radius: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #8c8c8c;
          border: none;
          background-color: rgba(217, 217, 217, 0.5);
          font-weight: 600;
          cursor: pointer;
        }

        button:hover {
          transform: scale(1.05);
        }

        button.active {
          background-color: var(--primary);
          color: #fff;
          display: block;
        }
      }

      .permissions {
        .show {
          display: block;
        }

        .hide {
          display: none;
        }
      }
    }
  }

  .form-buttons {
    display: flex;
    justify-content: center;
    padding: 50px 0;
  }
}

@media only screen and (min-width: 1024px) {
  .crear-usuario {
    width: 100%;
    .main-form {
      width: 75%;
      background-color: #fff;
      margin: 0 auto;
      border-radius: 10px;
      padding: 35px 38px;

      .main-form-fields {
        .form-title {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 16px;
        }
      }

      .main-form-dates {
        .form-title {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 16px;
        }

        .ant-picker {
          border-radius: 6px;
        }
      }

      .main-form-permissions {
        .form-title {
          font-size: 1rem;
          font-weight: 600;
          margin: 16px 0;
        }
        .main-form-permission-buttons {
          display: flex;
          justify-content: space-between;
          gap: 16px;
          flex-wrap: wrap;

          button {
            flex: 1;
            height: 40px;
            padding: 9px 19px;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #8c8c8c;
            border: none;
            background-color: rgba(217, 217, 217, 0.5);
            font-weight: 600;
            cursor: pointer;
          }

          button:hover {
            transform: scale(1.05);
          }

          button.active {
            background-color: var(--primary);
            color: #fff;
            display: block;
          }
        }

        .permissions {
          .show {
            display: block;
          }

          .hide {
            display: none;
          }
        }
      }
    }

    .form-buttons {
      display: flex;
      justify-content: center;
      padding: 50px 0;
    }
  }
}
