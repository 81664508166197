.navbar_container {
  background-color: var(--primary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  height: 70px;

  .navbar_img {
    padding: 5px;
    img {
      width: 100px;
      height: 37.5px;
    }
    cursor: pointer;
  }
  .nav_links {
    list-style-type: none;
    display: none;
    column-gap: 30px;
    padding-left: 0;
    margin-bottom: 0;

    .link_item {
      padding: 6px 18px;
      border-radius: 10px;
      color: #fff;

      .link_item-text {
        text-decoration: none;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        color: inherit;
      }

      .ant-space-item {
        font-family: "Roboto", sans-serif;
        cursor: pointer;
        display: flex;

        .anticon-down {
          font-size: 12px;
        }
      }
    }

    .active {
      background-color: rgba(255, 255, 255, 0.8);
      color: var(--primary);
      font-weight: 700;
    }
  }

  .nav_user {
    .nav_user_name {
      display: none;
      color: white;
      font-family: "Open Sans", sans-serif;
      cursor: default;
    }

    .navbar_avatar {
      display: none;
      align-items: center;
      column-gap: 5px;
      cursor: pointer;

      .avatar {
        background-color: #5e86d1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .menu_hamburger {
    display: block;
    .anticon-menu {
      color: #fff;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
}
.botttom_div{
  height: 38px;
  width: 95%;
  background-color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  border-radius: 0 0 15px 15px;
  display: flex;
  align-items: center;
  padding: 8px 21px;
  margin-bottom: 25px;
  .ant-breadcrumb {
    .ant-breadcrumb-link {
      text-transform: capitalize;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .navbar_container {
    background-color: var(--primary);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    height: 70px;

    .navbar_img {
      padding: 5px;
      img {
        width: 100px;
        height: 37.5px;
      }
      cursor: pointer;
    }
    .nav_links {
      list-style-type: none;
      display: flex;
      column-gap: 0px;
      padding-left: 0;
      margin-bottom: 0;

      .link_item {
        padding: 6px 18px;
        border-radius: 10px;
        color: #fff;

        .link_item-text {
          text-decoration: none;
          font-family: "Roboto", sans-serif;
          font-size: 14px;
          color: inherit;
        }

        .ant-space-item {
          font-family: "Roboto", sans-serif;
          cursor: pointer;
          display: flex;

          .anticon-down {
            font-size: 12px;
          }
        }
      }

      .active {
        background-color: rgba(255, 255, 255, 0.8);
        color: var(--primary);
        font-weight: 700;
      }
    }

    .nav_user {
      .nav_user_name {
        display: block;
        color: white;
        font-family: "Open Sans", sans-serif;
        cursor: default;
      }

      .navbar_avatar {
        display: flex;
        align-items: center;
        column-gap: 5px;
        cursor: pointer;

        .avatar {
          background-color: #5e86d1;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .menu_hamburger {
      display: none;
      .anticon-menu {
        color: #fff;
        font-size: 1.5rem;
        cursor: pointer;
      }
    }
  }
}
