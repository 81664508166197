.registro-movimientos-container {
  padding: 0 1.5rem;

  .volver {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    cursor: pointer;
    .anticon-left-circle {
      font-size: 1rem;
      color: var(--primary);
    }
  }

  .info-cards-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    margin: 1rem 0;
  }

  .movimientos-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 16px;

    .user-info-container {
      //use 1fr of the grid
      grid-column: span 1;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      // .user-info {
      //   background: #fff;
      //   min-height: 600px;
      //   border-radius: 6px;

      //   .user-avatar {
      //     padding: 16px;
      //   }

      //   .user-name {
      //     padding: 16px;
      //     font-weight: 600;
      //     max-width: 70%;
      //   }
      .user-info_boton {
        display: flex;
        justify-content: center;

        + {
          .user-info_boton {
            margin-top: 25px;
          }
        }
      }
      //   .user-info_section {
      //     display: flex;
      //     flex-direction: column;
      //     padding: 8px 16px;
      //     margin-bottom: 0;

      //     .user-info_section-title {
      //       margin-bottom: 0;
      //       line-height: 16px;
      //       margin-bottom: 4px;
      //     }

      //     .user-info_section-value {
      //       font-weight: 600;
      //       line-height: 16px;
      //     }
      //   }
      // }

      .modify-btn {
        button {
          width: 100%;
        }
      }
    }
 
    .movimientos-tables {
      //use 3 columns of grid
      grid-column: span 3;

      //tabs
      .ant-tabs {
        .ant-tabs-tab {
          border-radius: 6px 6px 0 0;
          border: 0;
        }
        .ant-tabs-nav {
          margin-bottom: 0;
        }

        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #000;
            font-weight: 700;
          }
        }
      }
    }
  }
}
