.detalle_solicitudes_container {
  padding: 8px;
  width: 90%;
  margin: 0 auto;
  // display: flex;
  // justify-content: center;

  .ant-table {
    .ant-table-thead tr th {
      background-color: #fff;
    }
  }
}
.ant-card {
  width: 100%;
  border-radius: 10px;
}
.capa_buttons {
  display: inline-block;
}
.title {
  margin-bottom: 100px;
}

.btn-container-solicitud {
  display: flex;
  gap: 100px;
  // flex-direction: column;
  // flex-wrap: wrap;
  row-gap: 16px;

  .type_selector {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    flex-grow: 1;

    .selector {
      flex-grow: 1;
      width: 100px;
      height: 38px;
      padding: 10px 10px;
      cursor: pointer;
      border-radius: 6px;
      background-color: #d9d9d980;
      color: #8c8c8c;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;

      &:active {
        transform: scale(1.1);
      }
    }

    .disabled {
      cursor: not-allowed;
      pointer-events:none;
    }

    .active {
      background-color: var(--primary);
      color: #fff;
    }
  }

  .action-btns {
    display: flex;
    gap: 16px;
    width: 100%;
    .secondary_button_container {
      width: 100%;
      button {
        min-width: 100%;
      }
    }
  }
}

.type_selector,
.action-btns {
  width: 100%;
}

.backHistory {
  font-size: 15px;
}

.form_detalle {
  .ant-col {
    padding: 2px;
    margin: 0;
  }
  .ant-input {
    // padding: 10px;
    border-radius: 3px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .ant-select-selector {
    border-radius: 3px;
  }
  .ant-select-selection-item {
    padding: 10px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) {
  .detalle_solicitudes_container {
    padding: 8px;
    width: 90%;
    margin: 0 auto;

    .ant-table {
      .ant-table-thead tr th {
        background-color: #fff;
      }
    }

    .btn-container-solicitud {
      flex-direction: row;
      flex-wrap: nowrap;
      .type_selector {
        flex-grow: 2;
        .selector {
          flex-grow: 0;
        }
      }
      .action-btns {
        max-width: 286px;
        min-width: 206px;
        display: flex;
        gap: 16px;
        justify-content: end;

        .secondary_button_container {
          max-width: 50%;
          button {
            max-width: 50%;
          }
        }
      }
    }
  }
}
