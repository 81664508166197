.crear-sector_container {
  max-width: 90%;
  margin: auto;
  border-radius: 10px;

  .ant-form {
    .ant-form-item-with-help {
      margin-bottom: 12px;
    }

    .crear-sector_main-form {
      .ant-collapse {
        border-radius: 10px;

        .ant-collapse-item {
          border-radius: 10px;

          .ant-collapse-header {
            border-radius: 10px 10px 0 0;
          }

          .ant-collapse-content {
            border-radius: 0 0 10px 10px;
          }

          .form-subtitle {
            font-size: 1.25rem;
            font-weight: 600;
            margin-bottom: 16px;
          }
        }
      }
    }
  }

  .crear-sector_form-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: 32px;
    padding-bottom: 32px;
  }
}

@media only screen and (min-width: 1024px) {
  .crear-sector_container {
    max-width: 90%;
    background-color: #fff;
    margin: 0 auto;
    padding: 32px 16px;
    border-radius: 10px;
    .ant-form {
      width: 90%;
      margin: 0 auto;

      .ant-input-group-lg .ant-input,
      .ant-input-group-lg > .ant-input-group-addon {
        padding: 0 11px;
      }

      .crear-sector_main-form {
        .ant-collapse {
          border-radius: 10px;

          .ant-collapse-item {
            border-radius: 10px;

            .ant-collapse-header {
              border-radius: 10px 10px 0 0;
            }

            .ant-collapse-content {
              border-radius: 0 0 10px 10px;
            }
          }

          .ant-collapse-content-box {
            padding: 16px 32px;
          }
        }
      }
    }

    .crear-sector_form-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 24px;
      margin-top: 32px;
    }
  }
}
