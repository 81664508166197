.ant-drawer {
  .ant-drawer-header {
    .ant-drawer-header-title {
      flex-direction: row-reverse;
    }
    .ant-drawer-close {
      color: #003087;
      font-size: 20px;
    }
  }
  .detalle_drawer_container {
    .detalle__sector {
      display: flex;
      justify-content: space-between;
      background-color: #f5f7f8;
      padding: 16px 25px;
      border-radius: 15px;
      margin: 5px 0 25px;
      font-weight: 600;
      font-size: 1.05rem;

      .detalle_sector_legend {
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;
        column-gap: 10px;

        .marker {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }

        .active {
          background-color: #14c159;
        }

        .inactive {
          background-color: red;
        }
      }
    }
    .body_detalle {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      .ant-row {
        padding: 0 31px;
        .estado_solicitud {
          display: flex;
          div.fondo_icon {
            background: #FDF1E9;
            border-radius: 6px;
            width: max-content;
            margin-right: 10px;
          }
        }
      }

    }

    .detalle_section {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 40px;
      padding: 0 10px;

      .detalle_item {
        .detalle_item_title {
          font-weight: 700;
          margin-bottom: 0;
        }
      }
    }

    .detalle_section ~ .detalle_section {
      grid-template-columns: 1.5fr 1fr;
      column-gap: 15%;
    }
  }
}
