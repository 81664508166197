.solicitudes_container {
  padding: 10px;

  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 6px 6px 0 0;
  }
  .ant-tabs {
    .ant-tabs-nav {
      margin-bottom: 0;
      .ant-tabs-tab {
        .ant-tabs-tab-btn {
          color: rgba(140, 140, 140, 1);
          .title_tab {
            display: flex;
            align-items: center;
          }
          .anticon {
            width: 32px;
            height: 32px;
            line-height: 36px;
            border-radius: 6px;
            justify-content: center;
          }

          .anticon-exclamation-circle {
            background: rgba(250, 219, 20, 0.08);
            color: #fadb14;
          }

          .anticon-check-circle {
            background: rgba(20, 193, 89, 0.08);
            color: #14c159;
          }

          .anticon-close-circle {
            background: rgba(255, 77, 79, 0.08);
            color: #ff4d4f;
          }

          .anticon-clock-circle {
            background: rgba(191, 191, 191, 0.08);
            color: rgba(191, 191, 191, 1);
          }
          .anticon-pause-circle {
            background: rgba(84, 192, 219, 0.1);
            color: #54C0DB;
          }
        }
      }

      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: rgba(38, 38, 38, 1);
          font-weight: 700;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .solicitudes_container {
    padding: 40px;
    .ant-tabs {
      .ant-tabs-nav {
        .ant-tabs-tab {
          .ant-tabs-tab-btn {
            color: rgba(140, 140, 140, 1);
            .anticon {
              width: 32px;
              height: 32px;
              line-height: 36px;
              border-radius: 6px;
            }

            .anticon-exclamation-circle {
              background: rgba(250, 219, 20, 0.08);
              color: #fadb14;
            }

            .anticon-check-circle {
              background: rgba(20, 193, 89, 0.08);
              color: #14c159;
            }

            .anticon-close-circle {
              background: rgba(255, 77, 79, 0.08);
              color: #ff4d4f;
            }
          }
        }

        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: rgba(38, 38, 38, 1);
            font-weight: 700;
          }
        }
      }
    }
  }
}
