@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap");
// @import url("https://fonts.cdnfonts.com/css/gotham");

@font-face {
  font-family: gotham;
  font-style: normal;
  font-weight: 400;
  src: local("Gotham"),
    url(https://fonts.cdnfonts.com/s/14898/GothamBook.woff) format("woff");
}
@font-face {
  font-family: gotham;
  font-style: italic;
  font-weight: 400;
  src: local("Gotham"),
    url(https://fonts.cdnfonts.com/s/14898/GothamBookItalic.woff) format("woff");
}
@font-face {
  font-family: gotham;
  font-style: normal;
  font-weight: 300;
  src: local("Gotham"),
    url(https://fonts.cdnfonts.com/s/14898/GothamLight.woff) format("woff");
}
@font-face {
  font-family: gotham;
  font-style: italic;
  font-weight: 300;
  src: local("Gotham"),
    url(https://fonts.cdnfonts.com/s/14898/GothamLightItalic.woff)
      format("woff");
}
@font-face {
  font-family: gotham;
  font-style: normal;
  font-weight: 500;
  src: local("Gotham"),
    url(https://fonts.cdnfonts.com/s/14898/GothamMedium.woff) format("woff");
}
@font-face {
  font-family: gotham;
  font-style: normal;
  font-weight: 500;
  src: local("Gotham"),
    url(https://fonts.cdnfonts.com/s/14898/GothamMedium_1.woff) format("woff");
}
@font-face {
  font-family: gotham;
  font-style: italic;
  font-weight: 500;
  src: local("Gotham"),
    url(https://fonts.cdnfonts.com/s/14898/GothamMediumItalic.woff)
      format("woff");
}
@font-face {
  font-family: gotham;
  font-style: normal;
  font-weight: 700;
  src: local("Gotham"),
    url(https://fonts.cdnfonts.com/s/14898/GothamBold.woff) format("woff");
}
@font-face {
  font-family: gotham;
  font-style: normal;
  font-weight: 700;
  src: local("Gotham"),
    url(https://fonts.cdnfonts.com/s/14898/Gotham-Bold.woff) format("woff");
}
@font-face {
  font-family: gotham;
  font-style: italic;
  font-weight: 700;
  src: local("Gotham"),
    url(https://fonts.cdnfonts.com/s/14898/GothamBoldItalic.woff) format("woff");
}
@font-face {
  font-family: gotham black;
  font-style: normal;
  font-weight: 500;
  src: local("Gotham Black"),
    url(https://fonts.cdnfonts.com/s/14898/Gotham-Black.woff) format("woff");
}
@font-face {
  font-family: gotham light;
  font-style: normal;
  font-weight: 500;
  src: local("Gotham Light"),
    url(https://fonts.cdnfonts.com/s/14898/Gotham-Light.woff) format("woff");
}
@font-face {
  font-family: gotham thin;
  font-style: normal;
  font-weight: 500;
  src: local("Gotham Thin"),
    url(https://fonts.cdnfonts.com/s/14898/Gotham-Thin.woff) format("woff");
}
@font-face {
  font-family: gotham xlight;
  font-style: normal;
  font-weight: 500;
  src: local("Gotham XLight"),
    url(https://fonts.cdnfonts.com/s/14898/Gotham-XLight.woff) format("woff");
}
@font-face {
  font-family: gotham book;
  font-style: italic;
  font-weight: 500;
  src: local("Gotham Book"),
    url(https://fonts.cdnfonts.com/s/14898/Gotham-BookItalic.woff)
      format("woff");
}
@font-face {
  font-family: gotham thin;
  font-style: italic;
  font-weight: 500;
  src: local("Gotham Thin"),
    url(https://fonts.cdnfonts.com/s/14898/Gotham-ThinItalic.woff)
      format("woff");
}
@font-face {
  font-family: gotham ultra;
  font-style: italic;
  font-weight: 500;
  src: local("Gotham Ultra"),
    url(https://fonts.cdnfonts.com/s/14898/Gotham-UltraItalic.woff)
      format("woff");
}
@font-face {
  font-family: gotham xlight;
  font-style: italic;
  font-weight: 500;
  src: local("Gotham XLight"),
    url(https://fonts.cdnfonts.com/s/14898/Gotham-XLightItalic.woff)
      format("woff");
}

:root {
  * {
    box-sizing: border-box;
    font-family: "Gotham", sans-serif, Arial, Helvetica;
    // line-height: initial;
  }

  .ant-radio-inner:after {
    background-color: var(--primary);
  }

  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 6px 6px;
  }

  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 6px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 36px;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input {
    min-height: 36px;
    border-radius: 6px;
  }

  .ant-pagination-item {
    border-radius: 6px;
    //background-color: #f3f5f7;
    background-color: #fff;
    border: none;
    a {
      color: #8c8c8c;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      border-radius: 6px;
    }
  }

  .ant-pagination-item,
  .ant-pagination-total-text {
    line-height: 32px;
  }

  .ant-pagination-item-active {
    background-color: var(--primary);
    a {
      color: #fff;
    }
  }

  .ant-table-cell {
    a {
      color: var(--primary);
      font-weight: 700;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  body {
    margin: 0;
  }

  //primary color
  // --primary: rgba(25, 82, 190, 1);
  --primary: #003087;
  --primary-60: rgba(25, 82, 190, 0.6);
  --primary-40: rgba(25, 82, 190, 0.4);
  --primary-20: rgba(25, 82, 190, 0.2);
  --primary-10: rgba(25, 82, 190, 0.1);
  --primary-05: rgba(25, 82, 190, 0.05);

  //secondary
  --secondary: rgba(0, 57, 117, 1);
  --secondary-60: rgba(0, 57, 117, 0.6);
  --secondary-40: rgba(0, 57, 117, 0.4);

  body {
    background-color: #f3f5f7;
  }
}

// body {
//   background-color: #e5e5e5;
// }

.container {
  margin: 20px;
}

//components
@import "./components/Navbar.scss";
@import "./components/StatCard.scss";
@import "./components/CustomTabs.scss";
@import "./components/CerdosContainer.scss";
@import "./components/Input.scss";
@import "./components/Buttons.scss";
@import "./components/AccionesZonasDrawer.scss";
@import "./components/PavosContainer.scss";
@import "./components/PollosContainer.scss";
@import "./components/FilterDrawer.scss";
@import "./components/DetalleDrawer.scss";
@import "./components/NuevoNivelCerdos.scss";
@import "./components/CerdoTabsForm.scss";
@import "./components/CrearNivelCerdo.scss";
@import "./components/Login.scss";
@import "./components/RadioGroupCerdo.scss";
@import "./components/HistorialDrawer.scss";
@import "./components/ProfileDrawer.scss";
@import "./components/CrearNivelPollo.scss";
@import "./components/CrearNivelPavo.scss";
@import "./components/CrearZona.scss";
@import "./components/CrearSector.scss";
@import "./components/Collapsible.scss";
@import "./components/EditarZona.scss";
@import "./components/Alert.scss";
@import "./components/FormularioRolesContainer.scss";
@import "./components/FormularioRoles.scss";
@import "./components/NavbarDrawer.scss";
@import "./components/TablaCreacionRoles.scss";
@import "./components/TablaSolicitudes.scss";
@import "./components/CrearUsuario.scss";
@import "./components/NochesVacio.scss";
@import "./components/DetalleSolicitud.scss";
@import "./components/FirstLogin.scss";
@import "./components/EditarSolicitud.scss";
@import "./components/FormularioPlantaAlimentos.scss";
@import "./components/CrearGranja.scss";
@import "./components/CrearSubZona.scss";
@import "./components/AlertModal.scss";
@import "./components/TablaMovimientos.scss";
@import "./components/TablaPlantaALimentos.scss";
@import "./components/ConfirmModal.scss";
@import "./components/FormularioPermisos.scss";
@import "./components/DeleteModal.scss";
@import "./components/TablaRegistroMovimientos.scss";
@import "./components/CrearExcepcion.scss";
@import "./components/CrearDeclaracion.scss";
@import "./components/UserDetails.scss";
@import "./components/TablaRegistroExcepciones.scss";
@import "./components/Modalsolicitud.scss";
@import "./components/PiramidesTablas.scss";
@import "./components/EditarSector.scss";
@import "./components/NuevaSolicitud.scss";
@import "./components/DeclaracionVisita.scss";
@import "./components/CodigoVerificacion.scss";
@import "./components/ModalRegistroMovimientos.scss";
@import "./components/UploadUsers.scss";
@import "./components/DrawerUploadingUsers.scss";
@import "./components//reporte.scss";
@import "./components/FiltroTablaMovimientos.scss";

@import "./components/TablaCuarentenas.scss";
@import "./components/DetalleCuarentena.scss";
@import "./components/CrearCuarentena.scss";
@import "./components/InvalidarExcepcion.scss";
//pages
@import "./pages/Dashboard.scss";
@import "./pages/Piramides.scss";
@import "./pages/Users.scss";
@import "./pages/Solicitudes.scss";
@import "./pages/FirstLogin.scss";
@import "./pages/RegistroMovimientos.scss";
@import "./pages/Cuarentenas.scss";
