.tabla-cuarentenas-container {
  background-color: #fff;

  .live-request {
    padding: 20px;

    .request-btn {
      display: inline-block;
      color: var(--primary);
      cursor: pointer;

      .anticon-redo {
        transform: rotate(-90deg);
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .ant-table-thead {
    tr {
      th {
        background-color: #fff;
        font-weight: 700;
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        border-bottom: none;

        &:nth-child(3) {
          padding: 8px;
        }
        div.fondo_icon {
          background: #FDF1E9;
          border-radius: 6px;
          width: max-content;
        }
      }
    }

    .statusCodeMove {
      border-radius: 10px;
      color: #fff;
      padding: 5px 8px;
      &.statusAprobado {
        background-color: #ebb907;
      }
      &.statusDenegado {
        background-color: #e82525;
      }
      &.statusFinalizado {
        background-color: #14c159;
      }
      &.statusPendiente {
        background-color: #ebb907;
      }
    }

    div.action {
      .ant-space-item {
        background: rgba(0, 48, 135, 0.08);
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        cursor: pointer;
      }
    }

    p.ver_detalle {
      color: var(--primary);
      border: 1px solid var(--primary);
      border-radius: 4px;
      margin-bottom: 0;
      text-align: center;
      padding: 4px 10px;
      cursor: pointer;
    }
  }
  .filter_container {
    display: flex;
    justify-content: space-between;
    padding: 15px;

    .filters {
      display: flex;
      column-gap: 15px;

      .mobile-filters {
        display: flex;
        column-gap: 10px;

        .filter-box {
          width: 35px;
          height: 35px;
          display: grid;
          place-items: center;
          background-color: var(--primary);
          border-radius: 4px;
          span {
            color: #fff;
            font-size: 20px;
          }
        }
      }

      .filter-btn {
        display: none;
      }
    }

    .filter_input,
    .filter_buttons {
      display: none;
    }
  }

  .ant-pagination {
    padding: 25px 15px;

    .ant-pagination-disabled {
      button {
        border-radius: 6px;
      }
    }

    .ant-pagination-item {
      border-radius: 6px;
    }

    .ant-pagination-item-active {
      background-color: var(--primary);

      a {
        color: #fff;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .tabla-cuarentenas-container {
    background-color: #fff;
    border-radius: 10px;

    .ant-table-thead {
      tr {
        th {
          background-color: #fff;
          font-weight: 700;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          border-bottom: none;
        }
      }
    }
    .filter_container {
      display: flex;
      justify-content: space-between;
      padding: 20px;

      .cuarentenas-btn {
        column-gap: 15px;
        .ant-btn-primary {
          .button_text {
            font-size: 15px;
          }
        }

        .filter-btn {
          display: block;
        }
      }

      .filter_input {
        display: flex;
        column-gap: 20px;
      }

      .filter_buttons {
        display: flex;
        column-gap: 20px;
      }
    }
  }
}
