.crear-granja-container {
  background-color: #fff;
  max-width: 90%;
  margin: 0 auto;
  padding: 64px 32px;
  border-radius: 10px;

  .formulario {
    padding: 25px;
    border: 1px solid #f0f0f0;
    border-radius: 10px;

    .header {
      padding-bottom: 10px;
      font-size: 1rem;
      line-height: 22px;
      border-bottom: 1px solid #f0f0f0;
      font-weight: 600;
    }

    .inputs {
      padding: 25px 0;

      .label {
        margin-bottom: 5px;
      }
    }
  }
}

@media only screen and (min-width: 1025px) {
  .crear-granja-container {
    padding: 50px;
  }
}
