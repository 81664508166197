p {
  text-align: justify;
}


.container-upload-font {
  font-family: 'Gotham';
}


.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: '10px';
  margin-bottom: '30px';
}

.card-title {
  margin-bottom: 0;
  text-align: right;
}


.typography-text-title {
width: '155px';
height: '22px';
left: '346px';
top: '242px';
font-family: 'Gotham';
font-style: normal;
font-weight: '400px';
font-size: 18px;
line-height: 22px;
color: #414046;
}

.container-info {
  display: flex;
  align-items: center;
  padding-bottom: '10px';
  margin-top: 30px;
  border-radius: nullpx;
}

.confirm-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-container {
  display: flex;
  justify-content: center;
}

.confirm-container > * {
  margin: 40px 10px; /* Ajusta el valor a lo que necesites */
}

.back {
  display: flex;
  margin-left: 50px;
  margin-bottom: 50px;
  align-items: center;
  gap: 0.25rem;
  cursor: pointer;
  .anticon-left-circle {
    font-size: 1rem;
    color: var(--primary);
  }
}
