.input-label{
    padding-top: 24px;
    font-weight: 325;
    color:#414046;
    font-size: 16px;
}
.text{
    font-size: 14px;
    font-weight: 350;
    padding-bottom: 20px;
}
.label{
    font-size: 14px;
    padding-top: 24px;
}
.falseInput {
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    position: relative;
    display: inline-block;
    padding: 4px 11px;
    color: rgba(0,0,0,.85);
    font-size: 14px;
    line-height: 1.8;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    transition: all .3s;
    min-height: 36px;
    max-height: 36px;
    border-radius: 6px;
    width: 50px;
    min-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}
.nota{
    padding-bottom: 24px;
    font-size: 12px;
}

.react-multi-email{
    [data-tag] {
        border: 1px solid #D9D9D9 !important;
        background: #F0F0F0 !important;
        color: #000 !important;
        font-family: Gotham !important;
        font-size: 14px !important;
        font-style: normal !important;
        font-weight: 325 !important;
        height: 33px !important;
        [data-tag-item] {
            height: 15px;
        }
    }
} 