.editar_solicitud_container {
  max-width: 50%;
  margin: 25px auto;
  border-radius: 10px;

  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav,
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav {
    margin-bottom: 0px;
  }

  .ant-tabs {
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: black;
        font-size: 18px;
        font-weight: 700;
      }
    }

    .ant-tabs-tabpane {
      background-color: #fff;

      height: auto;
    }

    .ant-tabs-tabpane-active {
      height: auto;
    }
  }
}
