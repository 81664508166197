.editar-sector-container {
  max-width: 80%;
  margin: 0 auto;
  background-color: #fff;
  padding: 32px 64px;
  border-radius: 10px;
  .ant-form {
    .form-wrapper {
      .ant-collapse {
        .ant-collapse-item {
          .ant-collapse-header {
            border-radius: 10px 10px 0 0;
            background-color: #fff;

            .ant-collapse-header-text,
            .ant-collapse-expand-icon {
              font-size: 1.15rem;
              font-weight: 600;
            }

            .ant-collapse-extra {
              font-size: 0.75rem;
              line-height: 2rem;
              font-weight: 600;

              &:hover {
                transform: scale(1.1);
              }
            }
          }

          .ant-collapse-content {
            .sector-rows {
              .title {
                font-size: 1.5rem;
                font-weight: 600;
                margin-bottom: 16px;
              }
            }
          }
        }

        .ant-collapse-item:last-child > .ant-collapse-content {
          border-radius: 0 0 10px 10px;
        }

        .ant-collapse > .ant-collapse-item:last-child,
        .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
          border-radius: 10px;
        }
      }
    }
  }

  .form-buttons {
    margin-top: 100px;
  }
}
