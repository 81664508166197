.filter-criteria {
  .filter-item{
    .main-form-permission-buttons {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      flex-wrap: wrap;
      margin: 4px;

      button {
        flex: 1;
        height: 50px;
        padding: 6px 20px;
        margin: 1px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #8c8c8c;
        border: none;
        background-color: rgba(217, 217, 217, 0.5);
        font-weight: 600;
        font-size: 12px;
        cursor: pointer;
      }

      button:hover {
        transform: scale(1.05);
      }

      button.active {
        background-color: var(--primary);
        color: #fff;
        display: block;
      }
    }
  }
}