.modal-registros {
    &__radio{
        span{
            font-family: 'gotham'!important;
        }
    }
  &__textModal {
    font-family: gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 24px;
  }
  &__labelModal {
    font-size: 14px;
    padding-top: 24px;
    font-family: gotham;
    font-style: normal;
    font-weight: 325;
  }
  &__select {
    width: 100% !important;
  }
  &__fecha {
    width: 100% !important;
    border-radius: 6px !important;
  }
  &__hora {
    width: 100% !important;
    border-radius: 6px !important;

  }

  &__contenedor-hora.ant-space {
    width: 100% !important;
    .ant-space-item {
      width: 100% !important;
    }
  }

  &__contenedor-fecha {
    width: 100% !important;
  }
  &__button-secundary {
    width: 20%;
    .ant-btn {
      width: 100%;
      border: 1px solid #003087 !important;
      border-radius: 6px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: var(--primary);
        font-size: 16px;
        font-weight: 500;
        font-family: "Gotham", sans-serif;
      }
    }
  }
  &__button-primary {
    width: 30%;
    .ant-btn {
        width: 100%;
      border: 1px solid #003975;
      border-radius: 6px;
      background-color: var(--primary);
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;

      // gap: 10px;

      // span {
      //   color: #fff;
      //   font-size: 14px;
      //   font-weight: 500;
      //   font-family: "Gotham", sans-serif;
      // }

      .button_text {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        font-family: "Gotham", sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .anticon {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 16px !important;
        }
      }

      &:hover {
        background-color: var(--primary);
      }

      &:focus {
        background: #1952be;
      }
    }
  }
}
