.piramides {
  .piramides-wrapper {
    .piramides-header {
      background-color: #fff;
      padding: 36px 12px;
      display: flex;
      gap: 8px;
      flex-wrap: wrap;

      .piramides-filters {
        display: flex;
        gap: 8px;

        .iconButton {
          border: 1px solid #003975;
          color: #ffff;
          border-radius: 6px;
          background-color: var(--primary);
          height: 38px;
          width: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .ant-input {
          width: 50px;
          transition: width 0.5s ease-in-out;

          &:focus {
            width: 150px;
          }
        }

        .piramides-filters-sm {
          display: flex;
          gap: 8px;
        }

        .piramides-filters-lg {
          display: none;
        }
      }

      .piramides-actions {
        .piramides-actions-sm {
          display: flex;
          gap: 8px;
          .iconButton {
            border: 1px solid #003975;
            color: #ffff;
            border-radius: 6px;
            background-color: var(--primary);
            height: 38px;
            width: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .piramides-actions-lg {
          display: none;
        }
      }
    }

    .ant-table-wrapper {
      .ant-table-thead {
        th {
          font-weight: 700;
        }
      }

      .ant-table-tbody {
        .ant-table-cell-row-hover {
          background-color: rgba(232, 119, 34, 0.07);
        }

        div.action {
          display: flex;
          justify-content: center;
          .ant-space-item {
            background: rgba(0, 48, 135, 0.08);
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            cursor: pointer;
          }
        }
        .nombre-planta {
          display: flex;
          align-items: center;
          column-gap: 10px;
        }
      }
    }
  }
  
}
.drawer_history_plants{
  .ant-drawer-content {
    .ant-drawer-header {
      padding: 16px 39px;
    }
    .ant-drawer-body {
      padding: 24px 39px;
      .history_body {
        .ant-timeline{
          padding-left: 5px;
        }
      }
    }
  }

}

@media only screen and (min-width: 768px) {
  .piramides {
    .piramides-wrapper {
      .piramides-header {
        background-color: #fff;
        padding: 36px 12px;
        display: flex;
        justify-content: space-between;
        gap: 8px;
        flex-wrap: wrap;

        .piramides-filters {
          display: flex;
          gap: 8px;

          .ant-input {
            width: initial;

            &:focus {
              width: initial;
            }
          }

          .piramides-filters-sm {
            display: none;
          }

          .piramides-filters-lg {
            display: block;
          }
        }

        .piramides-actions {
          .piramides-actions-sm {
            display: none;
          }
          .piramides-actions-lg {
            display: flex;
            gap: 8px;
          }
        }
      }
    }
  }
}
