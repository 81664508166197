.mantenedor_roles_container {
  background-color: #fff;

  .ant-table-thead {
    tr {
      th {
        background-color: #fff;
      }
    }
    tr:first-child {
      th:first-child {
        padding-left: 60px;
      }
    }
    tr {
      th::before {
        width: 0 !important;
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        border-bottom: none;

        &:nth-child(3) {
          padding: 8px;
        }
      }
    }
    .ant-table-cell-fix-left {
      padding-left: 60px;
    }

    div.action {
      .ant-space-item {
        background: rgba(0, 48, 135, 0.08);
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        cursor: pointer;
      }
    }

    p.ver_detalle {
      color: var(--primary);
      border: 1px solid var(--primary);
      border-radius: 4px;
      margin-bottom: 0;
      text-align: center;
      padding: 4px 10px;
      cursor: pointer;
    }
    .content_status {
      display: flex;
      align-items: center;
      img {
        width: 7px;
      }
      .role_active{
        text-transform: capitalize;
        margin-left: 13px;
      }
    }
  
  }
  .filter_container {
    display: flex;
    justify-content: space-between;
    padding: 15px;

    .filters {
      display: flex;
      column-gap: 15px;

      .mobile-filters {
        display: flex;
        column-gap: 10px;

        .filter-box {
          width: 35px;
          height: 35px;
          display: grid;
          place-items: center;
          background-color: var(--primary);
          border-radius: 4px;
          span {
            color: #fff;
            font-size: 20px;
          }
        }
      }
    }

    .filter_input,
    .filter_buttons {
      display: none;
    }
  }

  .ant-pagination {
    padding: 25px 15px;

    .ant-pagination-disabled {
      button {
        border-radius: 6px;
      }
    }

    .ant-pagination-item {
      border-radius: 6px;
    }

    .ant-pagination-item-active {
      background-color: var(--primary);

      a {
        color: #fff;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .mantenedor_roles_container {
    background-color: #fff;

    .ant-table-thead {
      tr {
        th {
          background-color: #fff;
        }
      }
      tr:first-child {
        th:first-child {
          padding-left: 60px;
        }
      }
      tr {
        th::before {
          width: 0 !important;
        }
      }
    }

    .ant-table-tbody {
      tr {
        td {
          border-bottom: none;
        }
      }
      .ant-table-cell-fix-left {
        padding-left: 60px;
      }
    }
    .filter_container {
      display: flex;
      justify-content: space-between;
      padding: 20px;

      .filters {
        display: none;
        column-gap: 15px;
        .filter_item {
          width: 35px;
          height: 35px;
          border-radius: 4px;
          background-color: var(--primary);
          display: grid;
          place-items: center;

          span {
            color: #fff;
            font-size: 20px;
          }
        }
      }

      .filter_input {
        display: flex;
        column-gap: 20px;
      }

      .filter_buttons {
        display: flex;
        column-gap: 20px;
      }
    }
  }
}
