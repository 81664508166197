.crear-subzona-container {
  background-color: #fff;
  max-width: 90%;
  margin: 0 auto;
  padding: 24px;
  border-radius: 10px;

  .formulario {
    border: 1px solid #f0f0f0;
    border-radius: 10px;

    .header {
      padding: 10px 15px;
      font-size: 1rem;
      border-bottom: 2px solid #f0f0f0;
      font-weight: 700;
    }

    .select {
      margin-top: 25px;
      padding: 10px 15px;
    }

    .inputs {
      padding: 10px 15px;

      .label {
        margin-bottom: 5px;
      }
    }
  }
}
