.crear-cuarentena {
  &__container {
    padding: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
  }

  &__contenedor {
    padding: 10px 80px !important;
    border-radius: 10px !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);

    .ant-picker-range {
      padding: 10px;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      margin-top: 0px;
      width: 100%;
    }
    .descripcionCuarentena {
      height: 113px;
    }
    .btn-nuevoColaborador {
      font-weight: 400;
      font-size: 16px;
      color: var(--primary);
      padding: 0;

      &:hover,
      &:focus {
        background: none;
        color: var(--primary);
      }
    }
  }

  &__title {
    font-weight: 100;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 0;
    font-family: "Gotham";
    font-weight: 400;
  }

  &__botonera {
    padding: 30px;

    .primary_button_container {
      margin-left: 20px;
    }
  }
  //ConfirmacionFirmaDeclaracion
  &__containerCuarentena {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  &__contenedorCuarentena {
    padding: 10px 80px !important;
    border-radius: 10px !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05);
  }

  &__pText {
    font-weight: 100;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 0;
    font-family: "Gotham";
    font-weight: 325;
    text-align: center;
  }
  &__titleCuarentena {
    font-weight: 100;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 0;
    font-family: "Gotham";
    font-weight: 325;
    text-align: center;
    margin-top: 30px;
  }
}

.cuarentena-stepper {
  .ant-steps-item > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: transparent;
    border: 3px solid #bfbfbf;
    width: 49px;
    height: 49px;

    .ant-steps-icon {
      color: #bfbfbf;
      top: 7px;
      font-weight: 500;
      font-size: 22px;
    }
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    border-color: #003087;
    .ant-steps-icon {
      color: #003087;
    }
  }
  .ant-steps-item-content .ant-steps-item-title {
    color: #bfbfbf !important;
  }
  .ant-steps-item-content .ant-steps-item-description {
    font-weight: 600;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-icon {
    border-color: #003087;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-icon
    .ant-steps-icon {
    color: #003087;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-content
    .ant-steps-item-description {
    color: #003087;
  }
  .ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-content
    .ant-steps-item-title {
    color: #bfbfbf;
  }
  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title:after {
    display: none;
  }
  .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title:after {
    display: none;
  }
}
.cuarentena-grupo {
  &-head {
    display: flex;
    justify-content: space-between;
  }
  &-title {
    font-weight: 700;
    font-size: 18px;
  }
  &-delete {
    .btn-eliminarColaborador {
      font-weight: 700;
      color: #003087;
      &:hover, &:focus {
        background-color: transparent;
      }
    }
  }
}
