.ant-drawer {
  .historial_drawer_container {
    .history_body {
      li:last-of-type {
        .ant-timeline-item-tail {
          border-left: 0;
        }
      }

      .ant-timeline-item {
        .ant-timeline-item-head-custom {
          font-size: 20px;
          color: var(--primary);
        }
      }
    }
  }
}
