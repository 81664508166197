.formulario-alimentos {
  background-color: #fff;
  width: 80%;
  margin: 0 auto;
  border-radius: 10px;

  .ant-input {
    border-radius: 6px;
    width: 100%;
    height: 38px;
  }

  .ant-input-affix-wrapper {
    padding: 0 11px;
    border-radius: 6px;
    max-height: 38px;
    .ant-input {
      max-height: 36px;
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px;
    height: 38px;
  }

  .ant-select {
    width: 100%;
  }

  .form-wrapper {
    padding: 1.5rem;

    .form-title {
      font-weight: 400;
      font-size: 1.25rem;
      text-align: center;

      .bold {
        font-weight: 700;
      }
    }

    .label {
      margin-bottom: 15px;
    }

    .ant-col {
      margin-bottom: 15px;
    }

    .ant-row {
      margin-bottom: 0;
    }

    .error-message {
      color: #f5222d;
      font-size: 0.75rem;
      margin-top: 5px;
    }
  }
}
.crear-planta__header {
  padding-left: 2rem;
  cursor: pointer;
  color: var(--neutral-12, #141414);
  font-family: Gotham;
  font-size: 18px;
  font-style: normal;
  font-weight: 325;
  line-height: 22.001px;
  display: flex;
  align-items: center;
  .anticon{
    margin-right: 10px;
    color: #003087;
    font-size: 24px
  }
}

@media only screen and (min-width: 1024px) {
  .formulario-alimentos {
    width: 60%;
    margin: 0 auto;
    border-radius: 10px;

    .form-wrapper {
      padding: 2rem 3rem;

      .form-title {
        font-weight: 400;
        text-align: left;

        .bold {
          font-weight: 700;
        }
      }

      .label {
        margin-bottom: 15px;
      }
    }
  }
}

.formulario-alimentos_editar-wrapper {
  .formulario-alimentos_editar {
    background-color: #fff;
    width: 80%;
    margin: 0 auto;
    border-radius: 10px;
    padding: 16px 24px;

    &__header {
      font-size: 1.5rem;
    }

    div.formulario-alimentos_editar__header {
      margin-bottom: 32px;
    }
  }

  .form-btns {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;

    .secondary_button_container,
    .primary_button_container {
      width: 80%;
      button {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .formulario-alimentos_editar-wrapper {
    .formulario-alimentos_editar {
      width: 60%;
      margin: 0 auto;
      border-radius: 10px;
      padding: 32px 48px;

      &__header {
        font-size: 1.5rem;
      }
    }

    .form-btns {
      flex-direction: row;
      gap: 32px;

      .secondary_button_container,
      .primary_button_container {
        width: 250px;
        button {
          width: 100%;
        }
      }
    }
  }
}
