.section_detalle_rol {
  align-self: self-start;
  padding-left: 44px;
  height: 30px;

  .title,
    .label {
      color: var(--neutral-12, #141414);
      font-family: Gotham;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22.001px;
      margin-bottom: 0;
  }
  .ico {
    color: #003087;
  }
}
.formulario_roles_container {
  background-color: #fff;
  width: 80%;
  margin: 0 auto;
  padding: 30px 40px;
  border-radius: 10px;

  .section {
    .title,
    .label {
      font-size: 16px;
      margin-bottom: 0;
      color: #414046;
      font-family: Gotham;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
    .bold {
      font-weight: 700;
    }

    .ant-input {
      border-radius: 6px;
    }
  }

  .type_section {
    .label {
      font-size: 16px;
      margin: 25px 0;
    }
    .type_selector {
      display: flex;
      column-gap: 10px;
      .selector {
        width: 80px;
        height: 40px;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 6px;
        background-color: #d9d9d980;
        color: #8c8c8c;
        text-align: center;
        font-weight: 700;

        &:active {
          transform: scale(1.1);
        }
      }

      .active {
        background-color: var(--primary);
        color: #fff;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #003087;
        border-color: #003087;
      }
    }
  }

  .formularios {
    .hidden {
      display: none;
    }

    .title_section,
    .title_permission {
      color: #414046;
      font-family: Gotham;
      font-size: 14px;
      font-style: normal;
      flex: 0 0 419.52px;
    }
    .gutter-row {
      opacity: 0.800000011920929;
      color: #141414;
      font-family: Gotham;
      font-size: 16px;
      font-style: normal;
      font-weight: 401;
      line-height: 32px; /* 200% */
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #003087;
        border-color: #003087;
      }
      .ant-checkbox-disabled .ant-checkbox-inner {
        background-color: #BFBFBF;
        border-color: #BFBFBF!important;
      }
      .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after{
        border-color: #FFFFFF!important;
      }
    }
    .gutter-row-check {
      flex: 0 0 179px;
    }
  }

  .ant-collapse {
    border-radius: 10px;
    margin-bottom: 40px;
    border: 1px solid #F0F0F0;

    .ant-collapse-header {
      background-color: #fff;
      height: 49.31px;
      font-family: Gotham;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22.001px;
      .ant-collapse-arrow{
        font-size: 14px !important;
      }
    }
    .ant-collapse-content {
      border: 1px solid #F0F0F0;
      .ant-collapse-content-box {
        padding: 16px 16px 16px 30px !important;
    }
    }
  }
}

@media screen and (min-width: 1024px) {
  .formulario_roles_container {
    background-color: #fff;
    width: 80%;
    margin: 0 auto;
    padding: 30px 40px;
    border-radius: 10px;
  
    .section {
      .title,
      .label {
        font-size: 16px;
        margin-bottom: 0;
        color: #414046;
        font-family: Gotham;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
      .bold {
        font-weight: 700;
      }
  
      .ant-input {
        border-radius: 6px;
      }
    }
  
    .type_section {
      .label {
        font-size: 16px;
        margin: 25px 0;
      }
      .type_selector {
        display: flex;
        column-gap: 10px;
        .selector {
          width: 80px;
          height: 40px;
          padding: 10px 20px;
          cursor: pointer;
          border-radius: 6px;
          background-color: #d9d9d980;
          color: #8c8c8c;
          text-align: center;
          font-weight: 700;
  
          &:active {
            transform: scale(1.1);
          }
        }
  
        .active {
          background-color: var(--primary);
          color: #fff;
        }
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #003087;
          border-color: #003087;
        }
      }
    }
  
    .formularios {
      .hidden {
        display: none;
      }
  
      .title_section,
      .title_permission {
        color: #414046;
        font-family: Gotham;
        font-size: 13px;
        font-style: normal;
        flex: 0 0 28%
      }
      .gutter-row {
        opacity: 0.800000011920929;
        color: #141414;
        font-family: Gotham;
        font-size: 13px;
        font-style: normal;
        font-weight: 401;
        line-height: 32px; /* 200% */
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #003087;
          border-color: #003087;
        }
        .ant-checkbox-disabled .ant-checkbox-inner {
          background-color: #BFBFBF;
          border-color: #BFBFBF!important;
        }
        .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after{
          border-color: #FFFFFF!important;
        }
      }
      .gutter-row-check {
        flex: 1 0 14%;
      }
    }
  
    .ant-collapse {
      border-radius: 10px;
      margin-bottom: 40px;
      border: 1px solid #F0F0F0;
  
      .ant-collapse-header {
        background-color: #fff;
        height: 49.31px;
        font-family: Gotham;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 22.001px;
        .ant-collapse-arrow{
          font-size: 14px !important;
        }
      }
      .ant-collapse-content {
        border: 1px solid #F0F0F0;
        .ant-collapse-content-box {
          padding: 16px 16px 16px 30px !important;
      }
      }
    }
  }
}
// @media screen and (min-width: 1440px) {
//   .formulario_roles_container {
//     background-color: #fff;
//     width: 80%;
//     margin: 0 auto;
//     padding: 30px 40px;
//     border-radius: 10px;
  
//     .section {
//       .title,
//       .label {
//         font-size: 16px;
//         margin-bottom: 0;
//         color: #414046;
//         font-family: Gotham;
//         font-size: 16px;
//         font-style: normal;
//         font-weight: 400;
//         line-height: 22px;
//       }
//       .bold {
//         font-weight: 700;
//       }
  
//       .ant-input {
//         border-radius: 6px;
//       }
//     }
  
//     .type_section {
//       .label {
//         font-size: 16px;
//         margin: 25px 0;
//       }
//       .type_selector {
//         display: flex;
//         column-gap: 10px;
//         .selector {
//           width: 80px;
//           height: 40px;
//           padding: 10px 20px;
//           cursor: pointer;
//           border-radius: 6px;
//           background-color: #d9d9d980;
//           color: #8c8c8c;
//           text-align: center;
//           font-weight: 700;
  
//           &:active {
//             transform: scale(1.1);
//           }
//         }
  
//         .active {
//           background-color: var(--primary);
//           color: #fff;
//         }
//         .ant-checkbox-checked .ant-checkbox-inner {
//           background-color: #003087;
//           border-color: #003087;
//         }
//       }
//     }
  
//     .formularios {
//       .hidden {
//         display: none;
//       }
  
//       .title_section,
//       .title_permission {
//         color: #414046;
//         font-family: Gotham;
//         font-size: 13px;
//         font-style: normal;
//         flex: 0 0 250.52px;
//       }
//       .gutter-row {
//         opacity: 0.800000011920929;
//         color: #141414;
//         font-family: Gotham;
//         font-size: 13px;
//         font-style: normal;
//         font-weight: 401;
//         line-height: 32px; /* 200% */
//         .ant-checkbox-checked .ant-checkbox-inner {
//           background-color: #003087;
//           border-color: #003087;
//         }
//         .ant-checkbox-disabled .ant-checkbox-inner {
//           background-color: #BFBFBF;
//           border-color: #BFBFBF!important;
//         }
//         .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after{
//           border-color: #FFFFFF!important;
//         }
//       }
//       .gutter-row-check {
//         flex: 1 0 0;
      
//       }
//     }
  
//     .ant-collapse {
//       border-radius: 10px;
//       margin-bottom: 40px;
//       border: 1px solid #F0F0F0;
  
//       .ant-collapse-header {
//         background-color: #fff;
//         height: 49.31px;
//         font-family: Gotham;
//         font-size: 16px;
//         font-style: normal;
//         font-weight: 700;
//         line-height: 22.001px;
//         .ant-collapse-arrow{
//           font-size: 14px !important;
//         }
//       }
//       .ant-collapse-content {
//         border: 1px solid #F0F0F0;
//         .ant-collapse-content-box {
//           padding: 16px 16px 16px 30px !important;
//       }
//       }
//     }
//   }
// }